import React, { FC } from 'react'
import * as Yup from 'yup'
import cx from 'classnames'

import { PictureProps } from '../../atoms/Picture'
import FormFieldInputWithSubmit, {
  FormFieldInputWithSubmitProps,
} from '../../form/fields/FormFieldInputWithSubmit'
import { ListIconHtmlProps } from '../../atoms/ListIconHtml'
import FormFieldCheckbox, {
  FormFieldCheckboxProps,
} from '../../form/fields/FormFieldCheckbox'
import { Icons } from '../../atoms/Icon'
import { HtmlProps } from '../../atoms/Html'

import * as SC from './styled'
import { RegisterBlockPosition } from './styled'

export type Values = {
  email: string
}

export type RegisterBlockProps = MinimalSeoBlock & {
  className?: string
  title?: string
  infos?: ListIconHtmlProps
  formTitle?: string
  formTitleTag?: React.ElementType
  imageProps?: PictureProps
  mobileImageProps?: PictureProps
  fieldProps?: FormFieldInputWithSubmitProps
  fieldCheckProps?: FormFieldCheckboxProps
  fieldCheck2Props?: FormFieldCheckboxProps
  legalText?: HtmlProps
  validation: {
    email?: string
    required?: string
    consent?: string
  }
  onSubmit: (v: Values) => void
  position?: RegisterBlockPosition
  successProps?: string
}

const RegisterBlock: FC<RegisterBlockProps> = (props) => {
  const {
    className,
    htmlTag,
    title,
    htmlTitleTag,
    infos,
    formTitle,
    formTitleTag,
    imageProps,
    mobileImageProps,
    fieldProps,
    fieldCheckProps,
    fieldCheck2Props,
    validation,
    legalText,
    onSubmit,
    position = RegisterBlockPosition.row,
    successProps,
  } = props

  return (
    <SC.RegisterBlock className={cx('RegisterBlock', className)} as={htmlTag}>
      {title && (
        <SC.Header>
          <SC.Title as={htmlTitleTag}>{title}</SC.Title>
          {!mobileImageProps && imageProps && (
            <>
              <SC.Background {...imageProps} aria-hidden />
              <SC.Overlay />
            </>
          )}
          {mobileImageProps && imageProps && (
            <>
              <SC.DesktopBackground {...imageProps} aria-hidden />
              <SC.MobileBackground {...mobileImageProps} aria-hidden />
              <SC.Overlay />
            </>
          )}
        </SC.Header>
      )}
      <SC.Content $position={position}>
        {infos && <SC.Infos {...infos} />}
        {successProps ? (
          <SC.SucessContainer>
            <SC.CheckMark icon={Icons.check} />
            <SC.Text>{successProps}</SC.Text>
          </SC.SucessContainer>
        ) : (
          <>
            <SC.Form
              initialValues={{ email: '', legal: false, consent: false }}
              validationSchema={Yup.object().shape({
                email: Yup.string()
                  .email(validation?.email)
                  .required(validation?.required),
                legal: Yup.boolean(),
                consent: Yup.boolean(),
              })}
              validateOnChange
              onSubmit={onSubmit}
            >
              {formTitle && (
                <SC.FormTitle
                  as={formTitleTag}
                  dangerouslySetInnerHTML={{ __html: formTitle ?? '' }}
                />
              )}
              <SC.Field
                name="email"
                Component={FormFieldInputWithSubmit}
                {...fieldProps}
              />
              <SC.LegalField
                name="legal"
                Component={FormFieldCheckbox}
                {...fieldCheckProps}
              />
              <SC.LegalField
                name="consent"
                Component={FormFieldCheckbox}
                {...fieldCheck2Props}
              />
              {legalText && <SC.Legal {...legalText} />}
            </SC.Form>
          </>
        )}
      </SC.Content>
    </SC.RegisterBlock>
  )
}

export default RegisterBlock
